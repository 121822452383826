#type-labels
  margin: 1rem 0
  position: relative
  text-align: right
  &:before
    content: 'Project Type'
    font-size: 1.1em
    font-weight: 700
    display: inline-block
    position: absolute
    right: 6px
    top: -2rem
