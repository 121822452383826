.menu.chunk
  &.body
    overflow: hidden
    flex-grow: 1
    border-left: 1px solid var(--pink)
    border-right: 1px solid var(--pink)
  // .item:before
  //   width: 0
  .icon.item
    &:first-child
      margin-right: -1px
    &:last-child
      margin-left: -1px
      &:before
        width: 0