@keyframes wiggle
  25%
    transform: rotate(15deg)
  75%
    transform: rotate(-15deg)
        
@keyframes slide
  0%
    left: 0
  100%
    left: 100%  

@keyframes fadeout
  0% 
    opacity: 1
  100%
    opacity: 0