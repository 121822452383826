.ui.cards 
  margin-top: 1rem

.chunk.nav.header
  background-color: var(--light-blue)
  position: sticky
  top: 0px
  z-index: 2
  padding: 10px
  margin: -10px -10px 0 -10px
  box-shadow: 0px 10px 15px var(--light-blue)
