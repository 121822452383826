.external.link
  .ui.label
    &:first-child, &:last-child
      margin: 0 0.14285714em
    background-color: var(--light-pink)
    border: 1px solid var(--pink)
    transition: transform 400ms
    &:hover
      transform: translateY(-3px)
    .detail 
      background-color: rgba(255,255,255,0.1)