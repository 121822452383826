.ui.label.checkbox-label
  display: inline-flex
  align-content: center
  cursor: pointer
  gap: 0.25rem
  border: 1px solid transparent
  transition: transform 400ms, border 400ms
  > * 
    display: inline-block
  .field input
    cursor: pointer
  .content
    padding-
  .content + .detail
    padding-left: 0.5rem
    margin: 0
    border-left: 1px solid var(--pink)
  &:hover
    transform: translateY(-3px)
  &.checked
    transform: translateY(-3px)
    border-color: var(--pink)