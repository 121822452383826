
#resume
  h1
    margin-top: -1.5rem
    padding: 4rem 5px 1rem
    background: linear-gradient(rgba(90,90,90,0.2), transparent)
    border-bottom: 1px solid var(--pink)
  .ui.card
    &:after
      position: absolute
      content: ' '
      display: none
      width: 1px
      height: 100%
      left: 0
      background: var(--pink)
      opacity: 1
      box-shadow: 0 0 5px 1px var(--dark-pink)
      overflow: visible
      visibility: visible
    &:hover
      &:after
        display: inline-block
        animation: slide 1s cubic-bezier(.33,.52,.36,.98) forwards, fadeout 1s cubic-bezier(.33,.52,.36,.98) forwards      

#resume-modal
  &.ui.modal
    & > .header, & > .content
      background: var(--light-blue)
  .react-pdf__Page__canvas
    margin: auto

  .react-pdf__Page__textContent, .react-pdf__Page__annotations
    display: none

#personal-statement
  font-size: 1.1em