#footer
  justify-content: center
  font-size: 2.5rem
  .item
    border-radius: 0.5rem
    border: 1px solid transparent
    transition: border-color 400ms, background-color 400ms, transform 400ms
    &:hover
      border-color: var(--blue)
      background-color: rgba(255,255,255,0.3)
      transform: translateY(-3px)