#about
  .splash
    margin-bottom: -3em
  .segment
    .grid
      .column
        box-shadow: -1px 0 0 0 var(--light-pink)
        &:first-child
          padding-bottom: 0 !important
    .header
      margin: 0.25em 0
      width: max-content
      padding-left: 1em
      float: right
      vertical-align: top
      border-bottom: 2px solid var(--pink)
  .ui.list, .ui.horizontal.list
    display: flex
    flex-flow: row wrap
    margin: 0.25em 0
    gap: 4px
    > .item
      position: relative
      font-size: 1.1rem
      padding: 6px 20px 6px 6px !important
      margin: 0px !important
      align-items: center
      display: inline-flex
      background-color: var(--light-pink)
      border-radius: 3px
      border: 1px solid var(--pink)
      overflow: hidden
      transition: transform 400ms, background-color 400ms
      > .icon
        display: inline-block
        font-size: 1.5em
        line-height: 1
        flex-shrink: 0
        width: 2em
      > .icon + .content
        width: unset
        padding-left: 0.25em
      &:before
        content: ' '
        position: absolute
        display: block
        top: 0
        left: 0
        bottom: 0
        right: 0
        background: transparent
        transition: background 400ms
        z-index: 1
      &:hover
        transform: translateY(-2px)
        > .icon
          animation: 400ms wiggle  2
        &:before
          background: rgba(255,255,255,0.2)

